<template>
  <div class="input-group position-relative">
    <input placeholder="Please input your wish item here..." class="form-control" v-on:keyup.enter="addWishItem" v-model="wishItem" type="text">
    <span class="input-group-text bg-trans text-white" @click="addWishItem"><fa icon="plus"></fa></span>
    <fa v-if="wishItem && wishItem.length > 0" class="close-icon" icon="times-circle"
        @click="wishItem=''"></fa>
  </div>
  <div class="mt-3 text-start text-base">
    Sample: HP 850 G4 i7 8G 256G 100pcs
  </div>
</template>

<script>

import {
  addToWishlist
  // , removeFromWishlist
} from '../../api/wishlist'

export default {
  name: 'AddWishlist',

  props: {
    isLoggedIn: Boolean
  },

  emits: [
    'itemAdded'
  ],

  data () {
    return {
      wishItem: ''
    }
  },

  methods: {
    addWishItem () {
      if (this.wishItem.length === 0) {
        return
      }

      if (!this.isLoggedIn) {
        this.$toast.info('Please login in to submit wish item')
        return
      }

      this.$loading.show('Add items to your wishlist...')
      addToWishlist({
        wish_item: this.wishItem
      }).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.$toast.success('Successfully submitted, we will try best to get it for you')
          this.wishItem = ''
          this.$emit('itemAdded', this.wishItem)
        } else {
          this.$toast.error('Failed to submit your wish item, please retry later')
        }
      }).catch(err => {
        this.$loading.hide()
        // this.$toast.error('Failed to submit your wish item, please retry later')
        console.log(err)
      })
    }
  }
}
</script>

<style langg="scss">

.close-icon {
  position: absolute;
  height: 1.2rem;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
  z-index: 9999;
}
</style>
