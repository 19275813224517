import request from '@/utils/request'

const wishlistApi = {
  AddToWishlist: '/b/wishlist/add',
  RemoveFromWishlist: '/b/wishlist/del',
  GetWishlist: '/b/wishlist/list'
}

export function getWishlist (params) {
  return request({
    url: wishlistApi.GetWishlist,
    method: 'post',
    data: params
  })
}

export function addToWishlist (params) {
  return request({
    url: wishlistApi.AddToWishlist,
    method: 'post',
    data: params
  })
}

export function removeFromWishlist (params) {
  return request({
    url: wishlistApi.RemoveFromWishlist,
    method: 'post',
    data: params
  })
}
